import * as Sentry from '@sentry/nextjs';
import { GetServerSideProps } from 'next';
import { NextSeo } from 'next-seo';
import QueryString from 'qs';

import IntroImage from '/public/android-chrome-512x512.png';

import LayoutRenderer, { Layout } from 'utils/convert';
import { languageCodeMap } from 'utils/localConvert';

import { pageService } from 'services/api';
import { DefaultNextSEOStrapi } from 'services/api/types';

const Page = ({ SEO, Layout }: { SEO: DefaultNextSEOStrapi; Layout: Layout }) => {
  const FRONTEND_DOMAIN = process.env.NEXT_PUBLIC_FRONTEND_DOMAIN || 'https://belga.be/';
  const BACKEND_DOMAIN = process.env.NEXT_PUBLIC_API_BASE || 'https://www-bo.belga.be/';

  return (
    <>
      {SEO && (
        <NextSeo
          title={SEO.title}
          description={SEO.description}
          openGraph={{
            title: SEO.openGraph?.title,
            description: SEO.openGraph?.description,
            url: `${process.env.NEXT_PUBLIC_API_BASE}${SEO.openGraph?.images?.data?.attributes?.url}`,
            siteName: SEO.openGraph?.siteName,
            type: SEO.openGraph?.type,
            images: [
              {
                url: SEO?.openGraph?.images?.data?.attributes.url
                  ? `${BACKEND_DOMAIN + SEO?.openGraph?.images?.data?.attributes.url}`
                  : IntroImage.src,
                width: SEO?.openGraph?.images?.data?.attributes.width || IntroImage.width,
                height: SEO?.openGraph?.images?.data?.attributes.height || IntroImage.height,
                alt: SEO?.openGraph?.images?.data?.attributes.alternativeText || 'Belga.be',
                type: SEO?.openGraph?.images?.data?.attributes.mime || 'image/png',
              },
            ],
          }}
          canonical={SEO.canonical}
        />
      )}

      {Layout && <LayoutRenderer layout={Layout} />}
    </>
  );
};

export default Page;

export const getServerSideProps: GetServerSideProps = async (context) => {
  const { locale } = context;
  const { page } = context.query;

  if (locale) {
    try {
      const getPageDataBySlugQuery = {
        populate: 'deep',
        locale: languageCodeMap[locale],
        filters: {
          url: {
            $eq: `${page}`,
          },
        },
      };

      const response = await pageService.getPageData(
        QueryString.stringify(getPageDataBySlugQuery, {
          encodeValuesOnly: true, // prettify URL
        }),
      );

      if (response.data[0]) {
        return {
          props: {
            Layout: response.data[0]?.attributes.Layout,
            SEO: response.data[0]?.attributes.SEO,
          },
        };
      } else {
        return {
          notFound: true,
          props: {},
        };
      }
    } catch (error) {
      Sentry.captureException(error);
      return {
        notFound: true,
        props: {},
      };
    }
  } else {
    return {
      notFound: true,
      props: {},
    };
  }
};
